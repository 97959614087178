import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar/Navbar";
import LandingScreen from "./screens/Landing/LandingScreen";
import SignInScreen from "./screens/SignInScreen";
// import Route from './screens/Route.js'
import ProfileScreen from "./screens/Profile/ProfileScreen";
import SignUpMarketerAccount from "./screens/SignUpMarketerAccountScreen";
import AboutScreen from "./screens/AboutScreen";
import PrivacyScreen from "./screens/PrivacyScreen";
import SignUpScreen from "./screens/SignUpScreen";
import SignUpWelcome from "./screens/SignUpWelcome";
import { AuthProvider } from "./contexts/AuthContext";
import BlogScreen from "./screens/BlogScreen";
import AccountRecovery from "./screens/AccountRecovery";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import UninstallScreen from "./screens/UninstallScreen";
import Footer from "./components/Footer/Footer";
import ThanksForSupportingScreen from "./screens/Thanks/ThanksForSupportingScreen";
function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/" component={LandingScreen} />
            <Route path="/signup" component={SignUpScreen} />
            <Route path="/SignUpWelcome" component={SignUpWelcome} />
            <Route path="/signin" component={SignInScreen} />
            <Route path="/blog" component={BlogScreen} />
            <PrivateRoute path="/profile" component={ProfileScreen} />
            <Route path="/manifesto" component={AboutScreen} />
            <Route path="/account-recovery" component={AccountRecovery} />
            <Route path="/support/:uid" component={LandingScreen} />
            <Route path="/uninstall/:uids?" component={UninstallScreen} />
            <Route path="/thanks/:uid" component={ThanksForSupportingScreen} />
            <Route path="/privacy" component={PrivacyScreen} />
            <Route path="/marketing" component={SignUpMarketerAccount} />
          </Switch>
          <link href="css/styles.css" rel="stylesheet" />
          {/* <!-- Scripts --> */}
          <script src="js/jquery.min.js"></script>
          <script src="js/popper.min.js"></script>
          <script src="js/bootstrap.min.js"></script>
          <script src="js/jquery.easing.min.js"></script>
          <script src="js/swiper.min.js"></script>
          <script src="js/jquery.magnific-popup.js"></script>
          <script src="js/validator.min.js"></script>
          <script src="js/scripts.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/chart.js@2.8.0"></script>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
{
  /* <Route path='/'>
<LandingScreen/>
</Route>



<Route path='/blog'>
<BlogScreen/>
</Route>

<Route path='/profile'>
<ProfileScreen/>
</Route>


<Route path='/manifesto'>
<ManifestoScreen/>
</Route>

<Route path='/account-recovery'>
<AccountRecovery/>
</Route> */
}
