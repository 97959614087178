import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ProductService from "../service/ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../css/table-style.css";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../contexts/AuthContext";
import { setOriginalNode } from "typescript";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import MaterialUIButton from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));
export const Table = () => {
  const {
    user,
    saveProductBackend,
    deleteProductBackend,
    editProductBackend,
    deleteSelectedProductsBackend,
    editPromotionBackend,
    deletePromotionBackend,
    deleteSelectedPromotionsBackend,
  } = useAuth();
  let emptyProduct = {
    id: null,
    code: "",
    // image: null,
    description: "",
    // category: null,
    link: "",
    // inventoryStatus: 'INSTOCK'
  };

  const [products, setProducts] = useState(user.promotions);
  // const [promotions, setPromotions] = useState(user.promotions);
  const [productDialog, setProductDialog] = useState(false);
  const [oldProduct, setOg] = useState(emptyProduct);
  const [edit, setEdit] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const classes = useStyles();
  useEffect(() => {}, []);
  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = async () => {
    let updatedProduct = {};
    let productList = [...products]; // new copy of state list
    console.log(`saveProduct product, ${JSON.stringify(product)}`);
    if (product.link) {
      // let temp = new URL(product.link);
      product.host = "host";
    }
    // edit is a field set on confirming changes
    if (edit) {
      console.log(`saveProduct:edit`);
      // await editProductBackend({ ...product, uid: user.uid }, oldProduct);
      console.log(`saveProduct:edit:product, ${JSON.stringify(product)}`);
      editPromotionBackend(product);

      // update local begin
      const index = findIndexById(product.id);
      // add new changed product to copied list
      productList[index] = product;
      // overwrite old list with new updated list
      setProducts(productList);
      // display success
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Product Updated",
        life: 3000,
      });
      // flip case identifier flag
      setEdit(false);
    } else {
      // new promtion / product
      editPromotionBackend(product)
        .then((promotion) => {
          console.log(
            `editPromotionBackend:response:${JSON.stringify(promotion)}`
          );
          productList.push(promotion);
          // overwrite old list with new updated list
          setProducts(productList);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        })
        .catch((error) => {
          console.log("unhandled promise rejection", error);
        });
    }
    console.log(`save updatedProduct ${JSON.stringify(updatedProduct)}`);
    setProductDialog(false);
    setProduct(emptyProduct); // ? unknown
    setSubmitted(true); // ? unknown
  };

  const editProduct = (product) => {
    console.log(`Table.editProduct:product, ${JSON.stringify(product)}`);
    setOg(product);
    setProduct({ ...product });
    setProductDialog(true);
    setEdit(true);
  };

  const confirmDeleteProduct = (product) => {
    console.log(`confirmDeleteProduct:product`);
    setProduct(product);
    console.log(`confirmDeleteProduct:product, ${JSON.stringify(product)}`);
    // if(product){deleteProductBackend(product)}
    setDeleteProductDialog(true);
  };
  // Icon Delete
  const deleteProduct = () => {
    console.log(`deleteProduct:product, ${JSON.stringify(product)} `);
    console.log(`deleteProduct:products, ${JSON.stringify(products)}`);
    let _products = products.filter((val) => val.id !== product.id);
    setProducts(_products);
    deleteProductBackend(product);
    deletePromotionBackend(product);
    setDeleteProductDialog(false);
    setProduct(emptyProduct);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Product Deleted",
      life: 3000,
    });
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 8; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    console.log(`confirmDeleteSelected`);
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    console.log(`deleteSelectedProducts, ${JSON.stringify(selectedProducts)}`);
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    deleteSelectedProductsBackend(selectedProducts);
    deleteSelectedPromotionsBackend(selectedProducts);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  // const onCategoryChange = (e) => {
  //     let _product = {...product};
  //     _product['category'] = e.value;
  //     setProduct(_product);
  // }

  const onInputChange = (e, name) => {
    // console.log(`onInputChange:e, ${JSON.stringify(e)}`);
    console.log(`onInputChange:name, ${JSON.stringify(name)}`);
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* <MaterialUIButton label="New" className="p-button-success p-mr-2" onClick={openNew} /> */}
        <MaterialUIButton
          variant="contained"
          color="primary"
          onClick={openNew}
          className={classes.button}
          startIcon={<AddIcon />}
        >
          New Link
        </MaterialUIButton>
        <MaterialUIButton
          variant="contained"
          color="secondary"
          onClick={confirmDeleteSelected}
          className={classes.button}
          disabled={!selectedProducts || !selectedProducts.length}
          startIcon={<DeleteIcon />}
        >
          Delete
        </MaterialUIButton>

        {/* <MaterialUIButton label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} uploadLabel="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} /> */}
        {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} /> */}
        <IconButton onClick={() => editProduct(rowData)}>
          <Edit />
        </IconButton>
        <IconButton onClick={() => confirmDeleteProduct(rowData)}>
          <Delete />
        </IconButton>
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">Manage Products</h5>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveProduct}
      />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  return (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />

      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />

        <DataTable
          ref={dt}
          style={{ marginTop: -23 }}
          value={products}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
        >
          <Column selectionMode="multiple"></Column>
          <Column field="link" header="Link"></Column>
          <Column field="code" header="Code"></Column>
          <Column field="description" header="Description"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: "450px" }}
        header="Product Details"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <div className="p-field">
          <label htmlFor="name">Link</label>
          <InputText
            id="name"
            value={product.link}
            onChange={(e) => onInputChange(e, "link")}
            required
            autoFocus
            className={classNames({ "p-invalid": submitted && !product.link })}
          />
          {submitted && !product.link && (
            <small className="p-invalid">Link is required.</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="description">Description</label>
          <InputTextarea
            id="description"
            value={product.description}
            onChange={(e) => onInputChange(e, "description")}
            className={classNames({
              "p-invalid": submitted && !product.description,
            })}
            required
            rows={3}
            cols={20}
          />
          {submitted && !product.description && (
            <small className="p-invalid">Description is required.</small>
          )}
        </div>

        <div className="p-field">
          <label htmlFor="name">Code</label>
          <InputText
            id="name"
            value={product.code}
            onChange={(e) => onInputChange(e, "code")}
            autoFocus
          />
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              Are you sure you want to delete <b>{product.description}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>Are you sure you want to delete the selected products?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
};
