import React, { useState, useRef } from "react";
import SimpleCard from "../../components/SimpleCard.js";
import { Link, useHistory } from "react-router-dom";
import { Table } from "../../components/Table";
import { useAuth } from "../../contexts/AuthContext";
import "./ProfileScreenStyles.css";
import Avatar from "@material-ui/core/Avatar";
import Lottie from "react-lottie";
import onlineShopperAnim from "../../lotties/online-shopper.json";
import "primeflex/primeflex.css";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import { Button } from "primereact/button";
import "./ButtonStyle.css";
import { BsClipboard } from "react-icons/bs";
import { Toast } from "primereact/toast";
import MaterialUIButton from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import {
  ThemeProvider,
  createTheme,
  createMuiTheme,
} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    textColor: "#5F63BA",
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    textColor: "#5F63BA",
  },
  overrides: {
    MuiButton: {
      root: {
        fontColor: "#5F63BA",
      },
    },
  },
}));

const ProfileScreen = (props) => {
  const [cardVisible, setCardVisible] = useState(false);
  const classes = useStyles();
  const { user, logout } = useAuth();
  console.log("ProfileScreen:user", JSON.stringify(user));
  const supportUrl = `https://joinaffy.com/thanks/${user.uid}`;
  const toast = useRef(null);
  const showMessage = (severity, summary, detail) => {
    toast.current.show({
      severity: `${severity}`,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  function openAccountDetails() {
    setCardVisible(true);
  }
  function copyToClipboard() {
    navigator.clipboard.writeText(supportUrl);
    showMessage("success", "Copied To Clipboard");
  }
  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: onlineShopperAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  console.log(`user: ${JSON.stringify(user)}`);

  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <div className={"profileScreen"}>
        <div style={{ textAlign: "center", height: "120px" }}>
          {/* Avitar  */}
          <div style={{ position: "absolute", marginTop: 20, right: 20 }}>
            <Avatar
              style={{ width: 50, height: 50, borderRadius: 25 }}
              onClick={() => openAccountDetails()}
              src={user.photoUrl}
              alt="/broken-image.jpg"
            />
          </div>
        </div>
        {/* Header */}
        <div style={{ marginBottom: "100", marginTop: 25 }}>
          <h1 className={"headerFontHeader"}>Affy Dashboard</h1>
          <p className={"headerFontSubtext"}>
            Share your link, and we will help you get commision on sales you
            made.
          </p>
        </div>
        {/* Card */}
        {cardVisible ? <SimpleCard setCardVisible={setCardVisible} /> : null}

        {/*  */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          <MaterialUIButton
            onClick={() => {
              copyToClipboard();
            }}
            className={classes.button}
            style={{
              textColor: "#5F63BA",
              textTransform: "none",
              backgroundColor: "#F3F7FD",
            }}
            variant="contained"
            endIcon={
              <BsClipboard style={{ color: "#5F63BA", marginLeft: 15 }} />
            }
          >
            {supportUrl}
          </MaterialUIButton>
        </div>

        {/* Table */}
        <div style={{ marginLeft: "8%", marginRight: "8%", marginTop: 50 }}>
          <div style={{ marginBottom: 100 }}>
            <div style={{ marginTop: 100, marginBottom: 50 }}>
              <Table />
            </div>
          </div>
        </div>
        <div>
          <Lottie
            style={{ marginBottom: -50 }}
            options={defaultOptions}
            height={500}
            width={500}
          />
        </div>
      </div>

      <div
        style={{ backgroundColor: "white", paddingTop: 100 }}
        className="footer"
      >
        <Footer />
      </div>
    </div>
  );
};
export default ProfileScreen;
