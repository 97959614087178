import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./FooterStyles.css";
import PropTypes from "prop-types";
interface Props {
  backgroundColor?: string;
}

export default class Footer extends Component<Props> {
  static defaultProps: Partial<Props> = {
    backgroundColor: "white",
  };
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className="footer"
        style={{ backgroundColor: this.props.backgroundColor }}
      >
        <Container style={{ justifyContent: "center" }}>
          <Row style={{ justifyContent: "space-around", flexWrap: "wrap" }}>
            <Row
              style={{
                flex: 1,
                alignItems: "center",
                alignContent: "center",
                flexWrap: "nowrap",
                minWidth: 400,
              }}
            >
              <Col style={{ textAlign: "center" }}>
                <h4>Product</h4>
                <ul className="list-unstyled li-space-lg p-small">
                  <li className="media">
                    <div className="media-body">
                      <a
                        className="footer-text"
                        href="https://chrome.google.com/webstore/detail/affy/abjgnhbegcelmgebinhlilgklenfdkjj?hl=en"
                      >
                        Extension
                      </a>
                    </div>
                  </li>
                  <li className="media">
                    <div className="media-body">
                      <a className="footer-text" href="/">
                        Android
                      </a>
                    </div>
                  </li>
                  <li className="media">
                    <div className="media-body">
                      <a className="footer-text" href="/">
                        iOS
                      </a>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <h4>Social</h4>
                <ul className="list-unstyled li-space-lg p-small">
                  <li className="media">
                    <div className="media-body">
                      <a
                        className="footer-text"
                        href="http://www.twitter.com/joinaffy"
                      >
                        Twitter
                      </a>
                    </div>
                  </li>
                  <li className="media">
                    <div className="media-body">
                      <a
                        className="footer-text"
                        href="http://www.instagram.com/joinaffy"
                      >
                        Instagram
                      </a>
                    </div>
                  </li>
                  <li className="media">
                    <div className="media-body">
                      <a
                        className="footer-text"
                        href="https://www.linkedin.com/company/joinaffy/?viewAsMember=true"
                      >
                        LinkedIn
                      </a>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row style={{ flex: 1, flexWrap: "nowrap", minWidth: 400 }}>
              <Col style={{ textAlign: "center" }}>
                <h4>Company</h4>
                <ul className="list-unstyled li-space-lg p-small">
                  <li className="media">
                    <div className="media-body">
                      <a className="footer-text" href="/manifesto">
                        About Us
                      </a>
                    </div>
                  </li>
                  <li className="media">
                    <div className="media-body">
                      <a className="footer-text" href="/signin">
                        Sign In
                      </a>
                    </div>
                  </li>
                  <li className="media">
                    <div className="media-body">
                      <a className="footer-text" href="/privacy">
                        Privacy
                      </a>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <h4>Contact</h4>
                <ul className="list-unstyled li-space-lg p-small">
                  <li className="media">
                    <div className="media-body">
                      <a className="footer-text" href="/">
                        joinaffy@gmail.com
                      </a>
                    </div>
                  </li>
                  <li className="media">
                    <div className="media-body">
                      <a className="footer-text" href="/">
                        (949) 529-2130
                      </a>
                    </div>
                  </li>
                  <li className="media">
                    <div className="media-body">
                      <a className="footer-text" href="/">
                        ​
                      </a>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    );
  }
}
