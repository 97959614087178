import React, {  useRef,useState } from 'react';
import { Toast } from 'primereact/toast';
import Navbar from '../components/Navbar/Navbar';
import "firebase/auth";
import {useAuth} from '../contexts/AuthContext';
import {Link, useHistory} from 'react-router-dom';
import { auth } from '../firebase';

export default function SignInScreen () {

    let severity = 'error';
    let summary = 'Failed to sign in';
    let detail = 'Incorrect email or password';
    const emailRef = useRef();
    const passwordRef = useRef();
    const {login,currentUser,getUser} = useAuth();
    const [error,setError] = useState('');
    const [loading,setLoading] = useState(false);
    const toast = useRef(null);
    const history = useHistory();
    const showMessage = (severity,summary,detail) => {
        toast.current.show({severity:'error', summary: summary, detail:detail, life: 9000});
    }
    async function handleSubmit() {

        console.log('SignInScreen:handleSubmit')
    
           setLoading(true)
            login(emailRef.current.value,passwordRef.current.value).then ( async (response) => {
            console.log(`response, ${JSON.stringify(response)}`);
            console.log(`currentUser: ${JSON.stringify(currentUser)}`);
            
            if(response && response.user.emailVerified){
                await getUser(auth.currentUser.uid);

                history.push("/profile")
            }
            else {
                 showMessage(error,summary,'Email is unverified'); 
            }
           }).catch(e => {
            console.log('error',e)
            showMessage(severity,summary,detail); 

        }) 
        setLoading(false)
        
    }


   



        return (
            <div>
                <Toast ref={toast} position="bottom-right" />
                <Navbar/>
    
   
  
    <header id="header" className="ex-2-header">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Login</h1>
                   <p>You don't have an account? Then please <Link className="white" style = {{fontWeight:'bold'}} to="/signup">Sign Up</Link>
                    </p> 
                    {/* <!-- Sign Up Form --> */}
                    <div className="form-container">
                        <div className="form-group">
                      
                            <input type="email" ref={emailRef} className="form-control-input notEmpty" id="lemail" required/>
                                {/* <label className="label-control" for="lemail">Email</label> */}
                            <label className="label-control" >Email</label>
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group">
                                <input type="password" ref={passwordRef} className="form-control-input notEmpty" id="lpassword" required/>
                                {/* <label className="label-control" for="lpassword">Password</label> */}
                                <label className="label-control">Password</label>
                                <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group" style = {{marginTop:-18,marginRight:-240}}>
                          <Link className="white" style ={{color:'#555',fontSize:14}} to="/account-recovery">Forgot Password?</Link>
                        </div>
                        <div className="form-group">
                              <button disabled={loading} type="submit" className="form-control-submit-button" onClick = {handleSubmit} id="signup-button">LOG IN</button>
                        </div>
                            <div className="form-message">
                                <div id="smsgSubmit" className="h3 text-center hidden"></div>
                            </div>
                            
                    </div> 

                    {/* <!-- end of sign up form -->
                    <a className="white" href="account-recovery.html">Forgot your password ?</a> */}
                     {/* <Link className="white" to="/account-recovery">Forgot your password ?</Link> */}
                </div> 
            </div> 
        </div> 
    </header>
	

                
            </div>
        )

}
