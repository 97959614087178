import React, {  useRef,useState,useEffect } from 'react';
import { Toast } from 'primereact/toast';
import Navbar from '../components/Navbar/Navbar';
import "firebase/auth";
import {useAuth} from '../contexts/AuthContext';
import {Link, useHistory} from 'react-router-dom';

export default function SignUpWelcome() {

    const {currentUser} = useAuth();
   
    const toast = useRef(null);
    const history = useHistory();
    const delay = ms => new Promise(res => setTimeout(res, ms));
    
   useEffect( async  () => {
        console.log(`currentUser: ${JSON.stringify(currentUser)}`);
       if(currentUser && currentUser.emailVerified){
            await delay(5);
           history.push("/profile")
       }
     }, []);
        return (
            <div>
                <Toast ref={toast} position="bottom-right" />
                <Navbar/>
    
   
  
    <header id="header" className="ex-2-header">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Welcome</h1>
                   
                   
                   
                    {/* <!-- end of sign up form -->
                    <a className="white" href="account-recovery.html">Forgot your password ?</a> */}
                </div> 
            </div> 
        </div> 
    </header>
    <div className="footer">
        <div className="container">
            <div className="row">

                <div className="col-md-4">
                    <div className="footer-col first">
                        <h4>Links</h4>
                        <ul className="list-unstyled li-space-lg p-small">
                            <li className="media">
                                
                                <div className="media-body"><a className="white" href="attributions.html">Attributions</a></div>
                            </li>
                            <li className="media">
                                
                                {/* <div className="media-body"><a target="_blank" className="white" href="">Media</a></div> */}
                            </li>
                           
                        </ul>
                    </div> 
                </div> 

                <div className="col-md-4">
                    <div className="footer-col last">
                        <h4>Social</h4>
                        <ul className="list-unstyled li-space-lg p-small">
                            <li className="media">
                                <i className="fab fa-twitter"> </i>
                                {/* <div className="media-body"><a target="_blank" className="white" href="http://www.twitter.com/joinaffy">@joinaffy</a></div> */}
                            </li>
                            <li className="media">
                                <i className="fab fa-instagram"> </i>
                                {/* <div className="media-body"><a target="_blank" className="white" href="http://www.instagram.com/joinaffy">@joinaffy</a></div> */}
                            </li>
                           
                        </ul>
                    </div> 
                </div> 

                <div className="col-md-4">
                    <div className="footer-col last">
                        <h4>Contact</h4>
                        <ul className="list-unstyled li-space-lg p-small">

                            <li className="media">
                                <i className="fas fa-envelope"></i>
                                {/* <div className="media-body"><a target="_blank"  className="white" href="mailto:joinaffy@gmail.com">joinaffy@gmail.com</a></div> */}
                            </li>
                            <li className="media">
                                <i className="fas fa-phone"></i>
                                <div className="media-body">(949) 529-2130</div>
                            </li>
                        </ul>
                    </div> 
                </div> 
            </div> 
        </div> 
    </div> 


    
    	

                
            </div>
        )

}
