import React, { Component } from "react";
// import '../components/ProfileHeaderStyles.css'
import "../css/AboutScreenStyles.css";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import "primeflex/primeflex.css";
import { Container, Row, Col } from "react-bootstrap";
import Offers from "../assets/images/Offers.png";
import Support from "../assets/images/Support.png";

export default class AboutScreen extends Component {
  render() {
    // const header = <img alt="Card" src='showcase/demo/images/usercard.png'/>;
    return (
      <div>
        <Navbar />

        <Container
          fluid
          className={"profileScreen"}
          style={{ textAlign: "center" }}
        >
          {/* <Row>
                        <h1 className = {"aboutLogo"}> Affy </h1>
                    </Row> */}
          <Row>
            <h1
              style={{ paddingTop: 120, paddingBottom: 10 }}
              className={"headerFontHeader"}
            >
              Support each other
            </h1>
          </Row>
          <Row>
            <p style={{ paddingBottom: 80 }} className={"headerFontSubtext"}>
              Affy empowers consumers to indirectly fund creators, causes, and
              projects. Consumers get discounts from codes/links for purchases,
              while providing support with no money out of pocket. Support
              content from people like that online math tutor you never properly
              thanked. For you creators we know affiliate sales conversion rates
              are low. Our mission is to change that, by allowing consumers to
              save your links and apply them when they are ready so you get
              credit for being the procurring cause of purchases. At affy above
              all you vote with your purchases for what brings value to your
              life.
            </p>
          </Row>
        </Container>

        <Container
          fluid
          style={{ textAlign: "center", marginTop: 70, marginBottom: 50 }}
        >
          <Row style={{ flexWrap: "nowrap" }}>
            <Col style={{ marginLeft: "15%", minWidth: 262 }}>
              <p style={{ paddingBottom: 80 }} className={"FontCard"}>
                Save links from creators you value
              </p>
            </Col>

            <Col style={{ marginRight: "15%", minWidth: 262 }}>
              <div>
                <img
                  className={"aboutImage"}
                  style={{ width: "100%", maxWidth: 300, minWidth: 150 }}
                  src={Offers}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          style={{ textAlign: "center", marginTop: 70, marginBottom: 50 }}
        >
          <Row style={{ flexWrap: "nowrap" }}>
            <Col style={{ marginLeft: "15%", minWidth: 262 }}>
              <img
                className={"aboutImage"}
                style={{ width: "100%", maxWidth: 300, minWidth: 150 }}
                src={Support}
              />
            </Col>

            <Col style={{ marginRight: "15%", minWidth: 262 }}>
              <div>
                <p style={{ paddingBottom: 80 }} className={"FontCard"}>
                  Check for new coupons and promoted products from followed
                  affiliates
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}
