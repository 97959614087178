import React, { Component } from "react";
import Footer from "../../components/Footer/Footer";
// Profile View
import Navbar from "../../components/Navbar/Navbar";
import "firebase/auth";
import { db } from "../../firebase";
import DataView from "./DataView";
import LogoGrey from "../../assets/images/logoGrey.png";
interface Props {
  match: {
    params: {
      uid: string;
    };
  };
}
interface State {
  loading: boolean;
  photoUrl: string;
  username: string;
  bio: string;
  products: any[];
  userFound: boolean;
}

export default class ThanksForSupportingScreen extends Component<Props, State> {
  adminUID: any;
  supportAdmin: any;
  products: any;
  constructor(props) {
    super(props);
    this.products = [];
    console.log(
      `ThanksForSupportingScreen:this.props, ${JSON.stringify(this.props)}`
    );
    this.adminUID = this.props.match.params.uid;
    this.state = {
      loading: true,
      userFound: false,
      photoUrl: "",
      username: "",
      bio: "",
      products: [],
      // productsView
    };
  }
  async componentDidMount() {
    db.collection("admin")
      .doc(this.adminUID)
      .get()
      .then((doc) => {
        console.log(`doc.data(): ${JSON.stringify(doc.data())}`);
        this.supportAdmin = doc.data();
        this.products = doc.data().links || [];
        console.log(`*this.products ${JSON.stringify(this.products)}`);

        this.setState({
          loading: false,
          userFound: true,
          photoUrl: this.supportAdmin.photoUrl
            ? this.supportAdmin.photoUrl
            : LogoGrey,
          username: this.supportAdmin.username,
          bio: this.supportAdmin.bio,
          products: this.products,
        });
        console.log(
          `ThanksForSupportingScreen:componentDidMount:this.supportAdmin,${JSON.stringify(
            this.supportAdmin
          )}`
        );
      })
      .catch(() => {
        this.setState({
          userFound: false,
          loading: false,
        });
      });
  }
  /**
   * If user is not found in db display no longer a user
   * @returns
   */
  NonUser = () => {
    return !this.state.userFound && !this.Loading ? (
      <div style={{ marginBottom: "100", marginTop: 25 }}>
        <h1 className={"headerFontHeader"}>Whoops no longer a valid user!</h1>
      </div>
    ) : null;
  };
  Profile = () => {
    return this.state.userFound ? (
      <div>
        {/* Header */}
        <div style={{ marginBottom: "100", marginTop: 25 }}>
          <h1 className={"headerFontHeader"}>Thanks for supporting</h1>
          <p className={"headerFontSubtext"}>{this.state.username}</p>
          <div style={{ justifyContent: "center" }}>
            <img
              className=""
              src={this.state.photoUrl}
              alt="ProfilePhoto"
              style={{
                height: 200,
                width: 200,
                borderRadius: "50%",
                objectFit: "cover",
                marginTop: -90,
              }}
            />
          </div>

          <p className={"headerFontSubtext"}>{this.state.bio}</p>
        </div>
        <div style={{ paddingBottom: 25 }}>
          <DataView products={this.state.products} />
        </div>
      </div>
    ) : null;
  };
  Loading = () => {
    return this.state.loading ? (
      <div style={{ flex: 1, flexDirection: "row" }}>
        <span>
          <h1 className={"headerFontHeader"}>Loading</h1>
          {/* <h1 className={"headerFontHeader"}>.</h1>
          <h1 className={"headerFontHeader"}>.</h1>
          <h1 className={"headerFontHeader"}>.</h1> */}
        </span>
      </div>
    ) : null;
  };
  render() {
    return (
      <div style={{ flex: 1 }}>
        <Navbar />
        <header id="header" className="ex-2-header">
          <div className="container">
            <this.Loading />
            <this.NonUser />
            <this.Profile />
          </div>
        </header>
        <div
          style={{
            backgroundColor: "white",
            paddingTop: 20,
            paddingBottom: 20,
          }}
          className="footer"
        >
          <Footer />
        </div>
      </div>
    );
  }
}
