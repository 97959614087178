import React, {useRef,useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import EditableLabel from 'react-inline-editing';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import IconButton from '@material-ui/core/IconButton';
import OutsideAlerter from './OutsideAlerter';
import PropTypes from "prop-types";
import {useHistory} from 'react-router-dom';
import {useAuth} from '../contexts/AuthContext';
import './simpleCardStyle.css'
import {Stat} from './Stat';
import Text from '../constants/Text'
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));


export default function SimpleCard(props) {
  const history = useHistory();
  const {logout,updateBio,photoUploadBackend,updateUsername,user,updateEmail,currentUser} = useAuth();
  const [username,setUsername] = useState(user.username);
  const [email,setEmail] = useState(currentUser.email);
  const [bio,setBio] = useState(user.bio);
  const classes = useStyles();

  useEffect(() => {
    
    // Update the document title using the browser API
   console.log(`length: ${user.links.length}`)
   console.log(`bio: ${bio}`)
  
   if(bio == ''|| bio==null || bio == undefined ){
     console.log(`bio is empty string`)
     setBio(Text.DEFAULT_BIO);
     updateBio(Text.DEFAULT_BIO);
   }
   if(username == ''|| username==null || username == undefined ){
    console.log(`username is empty string`)
    setUsername(Text.DEFAULT_USERNAME);
    updateUsername(Text.DEFAULT_USERNAME);
  }
  });

  // Focus
  function _handleFocusUsername(text) {
    console.log('Focused with text: ' + text);
  }
  const photoUpload = async (event) => {
    console.log(`onBasicUpload`);
    console.log(event.target.files[0]);
   photoUploadBackend(event.target.files[0]);
    
 }
  function _handleFocusBio(text) {
    console.log('Focused with text: ' + text);
  }
  function _handleFocusEmail(text) {
    console.log('Focused with text: ' + text);
  }

  // Focus Out
 function _handleFocusOutUsername(text) {
    console.log('Left editor with text: ' + text);
    if(username != text && text != ''){
      // set locally
      setUsername(text);
      // set backend
      updateUsername(text);
    }
    else if ( text == ''){
      text = Text.DEFAULT_USERNAME;
      if(username != text){
        setUsername(text);
        updateUsername(text);
      }
    }
  }
  function _handleFocusOutEmail(text) {
    console.log('Left editor with text: ' + text);
    if(email != text){
      // set locally
      setEmail(text);
      updateEmail(text);
      // set backend
    }
  }
  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "K", "M", "B","T"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
}
  function _handleFocusOutBio(text) {
    console.log('Left editor with text: ' + text);

    if(bio != text && bio != '' && text != ''){
      // set locally
      setBio(text);
      // set backend
      updateBio(text);
    }
    else if(text == ''){
      text = Text.DEFAULT_BIO;
      if(bio != Text.DEFAULT_BIO ){
        // set locally
        setBio(text);
        // set backend
        updateBio(text);
      }
      
    }
    
  }
  async function handlelogout(){
    await logout();
    history.push('/signin');
  }

  return (
    <OutsideAlerter Action ={props.setCardVisible}>
    <Card style = {{zIndex:1,position:'absolute',right: 30,width:'300px', top: 60}} >
      
      <CardContent style = {{justifyContent:'center',alignItems:'center'}}>
        <div style = {{paddingLeft:100,marginBottom: 10,alignItems: 'center',justifyContent:'center',alignContent:'center'}}>
          <Avatar style = {{justifyContent:"center",height:70,width:70,alignContent:'center',alignSelf:'center'}} src={user.photoUrl}  alt="/broken-image.jpg" />
          <input accept="image/*"  onChange={photoUpload} className={classes.input} id="icon-button-file" type="file" />
          <label style={{position:'absolute',top:60,right:105}} htmlFor="icon-button-file">
           
            <div style = {{width:36,height:36,borderRadius:18, backgroundColor:'white'}}>
              <IconButton  style = {{
                width:30,
                elevation:2,  
                margin: "12px 0px",
                boxShadow: "0px 0px 8px #ccc",
                borderRadius: "8px",
                color: "#253B56",
                height:30,
                borderRadius:15,
                marginLeft:3,
                marginTop:3, 
                backgroundColor:'white'
                }}color="primary" aria-label="upload picture" component="span">
                <PhotoCamera size="small"/>
              </IconButton>
            </div>
          
          </label>
        </div>
        {/* <form className={classes.root} noValidate autoComplete="off"> */}
        <div style = {{textAlign:'center',justifyContent:'center',marginVertical:10}}>
        
        <div style={{ marginTop:15}}/>
        <EditableLabel 
          text={`${username}`}
          labelClassName='username'
          inputClassName='username'
          onFocus={_handleFocusUsername}
          onFocusOut={_handleFocusOutUsername}
        />
      
         <EditableLabel 
          text={`${bio}`}
          labelClassName='bio'
          inputClassName='bio'
          onFocusOut={_handleFocusOutBio}
          onFocus={_handleFocusBio}
        />

      <text>{email}</text>

      <div style= {{flexWrap:'nonwrap',marginTop:5}} className="p-grid p-fluid">
         <div className="p-col-12 p-md-6">
            <Stat valueStyle= {{fontSize:20,fontWeight:'bold'}} nameStyle={{fontSize:15,color:'#505050',fontWeight:'bold'}} name = "Links" value ={`${abbreviateNumber(user.links.length)}`}/>
         </div>
         <div className="p-col-12 p-md-6">
             <Stat valueStyle= {{fontSize:20,fontWeight:'bold'}}  nameStyle={{fontSize:15,color:'#505050',fontWeight:'bold'}}  name = "Supporters" value ={`${abbreviateNumber(user.supportersCount)}`}/> 
          </div>
      </div>

      </div>
    {/* </form> */}
     
      </CardContent>
      <CardActions>
        <Button onClick={handlelogout} size="small">
          <text style = {{color:'#505050',fontWeight:'bold'}}>
          SIGN OUT
          </text>
          
        </Button>
      </CardActions>
    </Card>
    </OutsideAlerter>
  );
}
SimpleCard.propTypes = {
  setCardVisible: PropTypes.element.isRequired
};