import React, { Component } from "react";
import { Button } from "primereact/button";
export default class SignUpMarketerAccountScreen extends Component {
  render() {
    return (
      <>
        <div className="grid grid-nogutter surface-0 text-800">
          <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
            <section>
              <span
                className="block text-6xl font-bold mb-1"
                style={{ lineHeight: 1 }}
              >
                Marketing of the next generation
              </span>
              <div
                className="text-6xl text-primary font-bold mb-3"
                style={{ marginTop: "20px", lineHeight: 1 }}
              >
                Find and connect with affiliate marketers
              </div>
              <p className="mt-0 mb-4 text-700 line-height-3">
                Reach audiances on vital platforms with in content advertisement
                and promotion.
              </p>

              <Button
                label="Learn More"
                type="button"
                className="mr-3 p-button-raised"
              />
              <Button
                label="Live Demo"
                type="button"
                className="p-button-outlined"
              />
            </section>
          </div>
          <div className="col-12 md:col-6 overflow-hidden">
            <img
              src="https://www.primefaces.org/primeblocks-react/assets/images/blocks/hero/hero-1.png"
              alt="hero-1"
              className="md:ml-auto block md:h-full"
              style={{ clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)" }}
            />
          </div>
        </div>

        <div className="surface-0 text-center" style={{ marginTop: "60px" }}>
          <div className="mb-3 font-bold text-3xl">
            <span className="text-900">One Platform, </span>
            <span className="text-blue-600">Many Solutions</span>
          </div>
          <div className="text-700 mb-6">
            Affy takes away the hassle & eliminates the many pitfalls of working
            with affiliate marketers
          </div>
          <div className="grid">
            <div className="col-12 md:col-4 mb-4 px-5">
              <span
                className="p-3 shadow-2 mb-3 inline-block"
                style={{ borderRadius: "10px" }}
              >
                <i className="pi pi-desktop text-4xl text-blue-500"></i>
              </span>
              <div className="text-900 text-xl mb-3 font-medium">
                Partner with Creators
              </div>
              <span className="text-700 line-height-3">
                Find YouTube & other influencers who align with your brand
              </span>
            </div>
            <div className="col-12 md:col-4 mb-4 px-5">
              <span
                className="p-3 shadow-2 mb-3 inline-block"
                style={{ borderRadius: "10px" }}
              >
                <i className="pi pi-lock text-4xl text-blue-500"></i>
              </span>
              <div className="text-900 text-xl mb-3 font-medium">
                Track Traffic
              </div>
              <span className="text-700 line-height-3">
                We track wich creator was the procurring cause of traffic so you
                can analyze which affiliates have greatest conversions
              </span>
            </div>
            <div className="col-12 md:col-4 mb-4 px-5">
              <span
                className="p-3 shadow-2 mb-3 inline-block"
                style={{ borderRadius: "10px" }}
              >
                <i className="pi pi-check-circle text-4xl text-blue-500"></i>
              </span>
              <div className="text-900 text-xl mb-3 font-medium">
                Easy to Use
              </div>
              <span className="text-700 line-height-3">
                We remove the burden of finding willing affiliates, and tracking
                what they do for your business
              </span>
            </div>
            <div className="col-12 md:col-4 mb-4 px-5">
              <span
                className="p-3 shadow-2 mb-3 inline-block"
                style={{ borderRadius: "10px" }}
              >
                <i className="pi pi-globe text-4xl text-blue-500"></i>
              </span>
              <div className="text-900 text-xl mb-3 font-medium">
                Partner With Charitess
              </div>
              <span className="text-700 line-height-3">
                By creating links/codes with affy partnerd charites, you can
                make sells & promote your brand in a positive way
              </span>
            </div>
            <div className="col-12 md:col-4 mb-4 px-5">
              <span
                className="p-3 shadow-2 mb-3 inline-block"
                style={{ borderRadius: "10px" }}
              >
                <i className="pi pi-github text-4xl text-blue-500"></i>
              </span>
              <div className="text-900 text-xl mb-3 font-medium">
                Make Sales
              </div>
              <span className="text-700 line-height-3">
                Affiliates provide links to products they endorse on your behalf{" "}
              </span>
            </div>
            <div className="col-12 md:col-4 md:mb-4 mb-0 px-3">
              <span
                className="p-3 shadow-2 mb-3 inline-block"
                style={{ borderRadius: "10px" }}
              >
                <i className="pi pi-shield text-4xl text-blue-500"></i>
              </span>
              <div className="text-900 text-xl mb-3 font-medium">
                Transpancy
              </div>
              <span className="text-700 line-height-3">
                Know what other brands/products the affiliate is promoting
              </span>
            </div>
          </div>
        </div>

        <div className="surface-0" style={{ marginTop: "60px" }}>
          <div className="text-900 font-bold text-6xl mb-4 text-center">
            Pricing Plans
          </div>
          <div className="text-700 text-xl mb-6 text-center line-height-3">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit
            numquam eligendi quos.
          </div>

          <div className="grid">
            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}
                >
                  <div className="text-900 font-medium text-xl mb-2">Basic</div>
                  <div className="text-600">Plan description</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">$9</span>
                    <span className="ml-2 font-medium text-600">per month</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Arcu vitae elementum</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Dui faucibus in ornare</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Morbi tincidunt augue</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                  <Button label="Buy Now" className="p-3 w-full mt-auto" />
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}
                >
                  <div className="text-900 font-medium text-xl mb-2">
                    Premium
                  </div>
                  <div className="text-600">Plan description</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">$29</span>
                    <span className="ml-2 font-medium text-600">per month</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Arcu vitae elementum</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Dui faucibus in ornare</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Morbi tincidunt augue</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Duis ultricies lacus sed</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <Button label="Buy Now" className="p-3 w-full" />
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 flex flex-column"
                  style={{ borderRadius: "6px" }}
                >
                  <div className="text-900 font-medium text-xl mb-2">
                    Enterprise
                  </div>
                  <div className="text-600">Plan description</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">$49</span>
                    <span className="ml-2 font-medium text-600">per month</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Arcu vitae elementum</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Dui faucibus in ornare</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Morbi tincidunt augue</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Duis ultricies lacus sed</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Imperdiet proin</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Nisi scelerisque</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <Button
                    label="Buy Now"
                    className="p-3 w-full p-button-outlined"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
