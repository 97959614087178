import React, { Component } from "react";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import "./DataView.css";
import logoGrey from "../../assets/images/logoGrey.png";
export default class DataViewLazyDemo extends Component {
  rows: number;
  datasource: any;

  constructor(props) {
    super(props);
    console.log(`this.props.products: ${JSON.stringify(this.props.products)}`);
    this.state = {
      products: this.props.products ? this.props.products : [],
      loading: true,
      first: 0,
      totalRecords: this.props.products.length,
    };
    this.rows = 6;

    this.itemTemplate = this.itemTemplate.bind(this);
    this.onPage = this.onPage.bind(this);
  }

  componentDidMount() {
    // setTimeout(() => {
    let localdata = [...this.state.products];
    localdata.forEach((element) => {
      fetch(`https://logo.clearbit.com/${element.link}`, { mode: "no-cors" })
        .then((response) => {
          console.log(`then`);
          console.log(`clearbit response, ${JSON.stringify(response)}`);
          element.img = response.url;
        })
        .catch((response) => {
          console.log(`clearbit:response, ${response}`);
          element.img = "";
        });
    });
    console.log(`componentDidMount:localdata, ${JSON.stringify(localdata)}`);
    this.datasource = localdata;
    this.setState({
      products: localdata,
      loading: false,
    });
    // }, 0);
  }

  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      const startIndex = event.first;
      const endIndex = Math.min(
        event.first + this.rows,
        this.state.totalRecords - 1
      );
      const newProducts =
        startIndex === endIndex
          ? this.datasource.slice(startIndex)
          : this.datasource.slice(startIndex, endIndex);

      this.setState({
        first: startIndex,
        products: newProducts,
        loading: false,
      });
    }, 1000);
  }

  renderListItem(data) {
    return (
      <div className="col-12">
        <div className="product-list-item">
          <img
            src={data.img ? data.img : logoGrey}
            alt={logoGrey}
            onError={(e) => (e.target.src = { logoGrey })}
          />
          <div className="product-list-detail">
            <div className="product-name">{data.name}</div>
            <div className="product-description">{data.description}</div>
            {data.code ? (
              <>
                <i className="pi pi-tag product-category-icon"></i>
                <span className="product-category">{data.code}</span>
              </>
            ) : null}
          </div>
          <div className="product-list-action">
            {/* <span className="product-price">${data.price}</span> */}
            <Button
              icon="pi pi-shopping-cart"
              label="Visit"
              onClick={() => {
                window.open(data.link);
              }}
              disabled={data.link ? false : true}
            ></Button>
            {/* <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span> */}
          </div>
        </div>
      </div>
    );
  }

  itemTemplate(product) {
    if (!product) {
      return <></>;
    }
    console.log(`itemTemplate:product: ${JSON.stringify(product)}`);
    return this.renderListItem(product);
  }

  renderHeader() {
    return (
      <div style={{ textAlign: "left" }}>
        {/* <DataViewLayoutOptions layout={this.state.layout} onChange={onOptionChange} /> */}
      </div>
    );
  }

  render() {
    const header = this.renderHeader();

    return (
      <div className="dataview-demo">
        <div className="card">
          <DataView
            value={this.state.products}
            layout={this.state.layout}
            header={header}
            itemTemplate={this.itemTemplate}
            lazy
            paginator
            paginatorPosition={"bottom"}
            rows={this.rows}
            totalRecords={this.state.totalRecords}
            first={this.state.first}
            onPage={this.onPage}
            loading={this.state.loading}
          />
        </div>
      </div>
    );
  }
}
