import React from "react";
import LogoImage from "../images/logobig.png";
import { Link, useHistory, NavLink } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";
import "./NavbarStyles.css";

export default function Navbar() {
  return (
    <div className="App">
      <ReactBootStrap.Navbar
        collapseOnSelect
        className="navbar-custom"
        expand="xl"
        variant="dark"
      >
        <div className="container">
          <NavLink
            style={{ textDecoration: "none", fontSize: 30 }}
            className="navbar-brand logo-image"
            to="/"
          >
            <text className={"Logo"}>Affy</text>
          </NavLink>
          <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
            <ReactBootStrap.Nav className="mr-auto"></ReactBootStrap.Nav>
            <ReactBootStrap.Nav>
              <NavLink to="/manifesto">
                <ReactBootStrap.Nav.Link
                  className="btn-outline-sm"
                  href="/manifesto"
                >
                  {" "}
                  ABOUT{" "}
                </ReactBootStrap.Nav.Link>
              </NavLink>
              {/* <Link to="/blog">
        <ReactBootStrap.Nav.Link className="btn-outline-sm" href="/blog"> BLOG </ReactBootStrap.Nav.Link>
        </Link> */}
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                to="/signin"
              >
                <ReactBootStrap.Nav.Link
                  className="btn-outline-sm"
                  href="/signin"
                >
                  CREATOR LOGIN{" "}
                </ReactBootStrap.Nav.Link>
              </NavLink>
            </ReactBootStrap.Nav>
          </ReactBootStrap.Navbar.Collapse>
        </div>
      </ReactBootStrap.Navbar>
    </div>
  );
}
