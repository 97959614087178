import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
require("firebase/firestore");

const firebaseConfig = {
  apiKey: "AIzaSyDYqYuxX3JUHObEI2owklg7oanEe67KACY",
  authDomain: "affy-7bc56.firebaseapp.com",
  databaseURL: "https://affy-7bc56.firebaseio.com",
  projectId: "affy-7bc56",
  storageBucket: "affy-7bc56.appspot.com",
  messagingSenderId: "796448860057",
  appId: "1:796448860057:web:55c20edcbe68b21ed75508",
  measurementId: "G-Y2SPEBG5BX",
};
firebase.initializeApp(firebaseConfig);
firebase.firestore();
var storage = firebase.storage();
var storageRef = storage.ref();
const db = firebase.firestore();
const auth = firebase.auth();
const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);
export { db, auth, increment, decrement, storageRef };
