import React from 'react';

export const Stat = props => {
    return(
        <div style={{display:'flex',flexDirection:'column'}}>
            <div>
                <text style={props.valueStyle}> {props.value} </text>
            </div>
            <div style={{marginTop:-5}}>
                <text style={props.nameStyle} >{props.name}</text>
            </div>
    </div>
    )
};
