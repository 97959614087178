import React, { Component } from "react";
import "firebase/auth";
import { db, decrement } from "../firebase";
import UninstallForm from "../components/UninstallForm/UninstallForm";
import PaperAirplane from "../assets/images/PaperAirplane.jpeg";

export default class UninstallScreen extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //decrement supportersCount for each creator uid passed in
    const uids = this.props.match.params.uids;
    if (uids) {
      const creatorUids = uids.split("-");
      creatorUids.forEach((uid) => {
        console.log(`uid ${uid}`);
        const ref = db.collection("supportersCount").doc(uid);
        ref.update({ supportersCount: decrement });
      });
    }
  }

  render() {
    const Header = () => {
      return (
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            height: "80px",
            flex: 1,
            textAlign: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              fontSize: "40px",
              fontFamily: "Pacifico-Regular",
              //   letterSpacing: 10,
              color: "black",
              textAlign: "center",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "5px",
              textDecoration: "none",
            }}
          >
            Affy
          </div>
          {/* <div style={{ margingLeft: "40%" }}> */}
          <div
            className="row cta-button"
            style={{ position: "absolute", right: "20px" }}
          >
            <div className="col-lg-12">
              <a
                className="btn-outline-reg page-scroll"
                href="https://chrome.google.com/webstore/detail/affy/abjgnhbegcelmgebinhlilgklenfdkjj?hl=en"
              >
                + Reinstall extension
              </a>
            </div>
          </div>
          {/* </div> */}
        </div>
      );
    };
    const Banner = () => {
      return (
        <div
          style={{
            // display: "flex",
            textAlign: "center",
            marginTop: "5%",
          }}
        >
          <div style={{ display: "block", width: "100%" }}>
            <h2
              style={{ display: "block", fontWeight: "bold", fontSize: "20" }}
            >
              Help us improve
            </h2>
          </div>
          <div style={{ display: "block", width: "100%" }}>
            <h6>Why did you uninstall affy?</h6>
          </div>
        </div>
      );
    };
    const GrayBackGround = (props) => {
      return (
        <div
          style={{
            display: "flex",
            zIndex: -2,
            height: "100vh",
            minHeight: "100vh",
            backgroundColor: "#f2f2f2",
          }}
        >
          {props.children}
        </div>
      );
    };
    const WhiteBackDrop = (props) => {
      return (
        <div
          style={{
            width: "90%",
            height: "85%",
            marginLeft: "5%",
            marginTop: "10%",
            position: "absolute",
            justifyContent: "center",
            border: "5px",
            backgroundColor: "white",
          }}
        >
          {props.children}
        </div>
      );
    };
    const RowColumns = () => {
      return (
        <div style={{}}>
          <div
            style={{
              display: "flex",
              // backgroundColor: "orange",
              justifyContent: "row",
              width: "100%",
              height: "100%",
            }}
          >
            {/* left */}
            <div
              style={{
                display: "block",
                justifyContent: "center",
                flex: 1,
                alignContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flex: 1,
                  width: "10%",
                }}
              />
              <img
                style={{
                  flex: 1,
                  display: "block",
                  maxWidth: "200px",
                  justifySelf: "center",
                  alignSelf: "center",
                  margin: "auto",
                }}
                src={PaperAirplane}
              />

              <h6
                style={{
                  flex: 1,
                  display: "block",
                  textAlign: "center",
                  maxWidth: "60%",
                  margin: "auto",
                  marginTop: "15px",
                }}
              >
                Did you know affy allows you to also contribute to charities
                with your everyday purchases?
              </h6>
            </div>
            {/* right */}
            <div style={{ flex: 1 }}>
              <UninstallForm />
            </div>
          </div>
        </div>
      );
    };
    return (
      <GrayBackGround>
        <Header />
        <WhiteBackDrop>
          <Banner />
          {/* <UninstallForm /> */}
          <RowColumns />
        </WhiteBackDrop>
      </GrayBackGround>
    );
  }
}
