import React, { Component } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import details3 from "../../images/details-3.png";
import details2 from "../../images/details-2.png";
import details1 from "../../images/details-1.png";
import howto1 from "../../images/how-to-1.png";
import howto2 from "../../images/how-to-2.png";
import howto3 from "../../images/how-to-3.png";
import headercreator from "../images/header-creator.svg";
import "./LandingScreenStyles.css";
// import Navbar from '../components/Navbar';
import { Link, useHistory } from "react-router-dom";
import Text from "../../constants/Text";

export default class LandingScreen extends Component {
  render() {
    return (
      <>
        {/* Header */}

        <header id="header" className="header">
          <Navbar />

          <div  className="container">
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-md-6" style={{ marginTop: 20 }}>
                <div className="text-container">
                  <h1>{Text.PROFILE_SCREEN_HEADLINE}</h1>
                  <p className="p-large">
                    Affy helps you <strong>save money</strong> and{" "}
                    <strong>support your favorite creators</strong> when
                    shopping online.
                  </p>
                  <a 
                    // style={{zIndex:999, position:"absolute"}}
                    className="btn-solid-lg page-scroll"
                    href="https://chrome.google.com/webstore/detail/affy/abjgnhbegcelmgebinhlilgklenfdkjj?hl=en"
                  >
                    Add to Browser - It's Free
                  </a>
                  
                  
                </div>
              </div>

              {/* <div className="p-col-12 p-md-6" style={{marginTop:20}}>
                        <div className="image-container">
                            <div className="img-wrapper">
                                <img className="img-fluid" src={headercreator} alt="alternative"/>
                            </div> 
                        </div> 
                    </div>  */}
            </div>
            <br/>
          </div>
    
        </header>
        
        
          <svg className="header-frame" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1920 310">
              <defs></defs>
              <title>header-frame</title>
              <path className="cls-1" d="M0,283.054c22.75,12.98,53.1,15.2,70.635,14.808,92.115-2.077,238.3-79.9,354.895-79.938,59.97-.019,106.17,18.059,141.58,34,47.778,21.511,47.778,21.511,90,38.938,28.418,11.731,85.344,26.169,152.992,17.971,68.127-8.255,115.933-34.963,166.492-67.393,37.467-24.032,148.6-112.008,171.753-127.963,27.951-19.26,87.771-81.155,180.71-89.341,72.016-6.343,105.479,12.388,157.434,35.467,69.73,30.976,168.93,92.28,256.514,89.405,100.992-3.315,140.276-41.7,177-64.9V0.24H0V283.054Z"/>
          </svg>
     


        <div className="cards-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2-heading">How it works</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-image">
                    <img className="img-fluid" src={howto1} alt="alternative" />
                  </div>

                  <div className="card-body">
                    <h3 className="card-title">1. Add in Seconds</h3>
                    <p>
                      Two clicks to add for <strong>free</strong> and select
                      your ❤️ creators.
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="card-image">
                    <img className="img-fluid" src={howto2} alt="alternative" />
                  </div>
                  <div className="card-body">
                    <h3 className="card-title">2. Shop Normally</h3>
                    <p>
                      We'll help you find <strong>deals</strong> that also
                      support creators.
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="card-image">
                    <img className="img-fluid" src={howto3} alt="alternative" />
                  </div>
                  <div className="card-body">
                    <h3 className="card-title">3. Save and Support</h3>
                    <p>
                      One click to <strong>save money</strong> and{" "}
                      <strong>support</strong> your ❤️ creators
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Features --> */}
        <div id="features" className="tabs" style={{ marginBottom: -100 }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Details 2--> */}
                <div id="details" className="basic-1-1">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="image-container">
                          <img
                            className="img-fluid"
                            src={details2}
                            alt="alternative"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-container">
                          <h2>Support Creators</h2>
                          <p>
                            While you shop, we search for{" "}
                            <strong>better deals</strong>. We integrate
                            affiliate links from creators you support so they
                            get a percentage of the purchase price instead of
                            the retailer.
                          </p>
                          <ul className="li-space-lg">
                            <li>
                              <div className="media-body">
                                Supporting is <strong>free</strong>
                              </div>
                            </li>
                            <li>
                              <div className="media-body">
                                No more <strong>ads</strong> or{" "}
                                <strong>sketchy sponsors</strong>
                              </div>
                            </li>
                            <li>
                              <div className="media-body">
                                Charities and open source projects coming soon
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="details" className="basic-1-1">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="image-container">
                          <img
                            className="img-fluid"
                            src={details1}
                            alt="alternative"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-container">
                          <h2>Automatic Savings</h2>
                          <p>
                            Shop normally online and while we search the web for{" "}
                            <strong>better deals</strong>. We try to find better
                            deals for you that also support creators.
                          </p>
                          <ul className="li-space-lg">
                            <li>
                              <div className="media-body">
                                We find <strong>lower prices</strong> for the
                                same product you search
                              </div>
                            </li>
                            <li>
                              <div className="media-body">
                                Savings are all applied with{" "}
                                <strong>one click</strong>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Details 1--> */}
                <div id="details" className="basic-1-2">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="image-container">
                          <img
                            className="img-fluid"
                            src={details3}
                            alt="alternative"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-container">
                          <h2>Privacy Centric</h2>
                          <p>
                            Affy supports your right to privacy. Lots of
                            companies provide services to harverst your data and
                            sell it. Affy is not one of those companies.
                          </p>
                          <ul className="li-space-lg">
                            <li>
                              <div className="media-body">
                                We do not sell your <strong>data</strong>
                              </div>
                            </li>
                            <li>
                              <div className="media-body">
                                No account needed
                              </div>
                            </li>
                            <li>
                              <div className="media-body">
                                <strong>No ads</strong> or{" "}
                                <strong>upsells</strong> from Affy
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container cta-1">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2-heading">
                  Ready to start supporting and saving?
                </h1>
              </div>
            </div>
            <div className="row cta-button">
              <div className="col-lg-12">
                <a
                  className="btn-solid-reg page-scroll"
                  href="https://chrome.google.com/webstore/detail/affy/abjgnhbegcelmgebinhlilgklenfdkjj?hl=en"
                >
                  Add to Browser - It's Free
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer backgroundColor={"#f3f7fd"} />
      </>
    );
  }
}
